import { HTMLAttributes, PropsWithChildren } from 'react'

import { StyledSection } from './fire-section.styles'
import { SectionProps } from './fire-section.types'

export const Section: React.FC<
  PropsWithChildren<SectionProps & HTMLAttributes<HTMLDivElement>>
> = ({ children, bleed, background, verticalSpacing, ...props }) => {
  if (!children) return null

  return (
    <StyledSection
      {...props}
      $bleed={bleed}
      $background={background}
      $verticalSpacing={verticalSpacing}
    >
      {children}
    </StyledSection>
  )
}
